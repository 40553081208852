import { IPublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { Slide, toast } from 'react-toastify';
import { apiConfig, b2cPolicies } from '../Authentication/AuthConfig';

class ApiHelper {
    async callApiWithToken(token: string, endpoint: string, method: string, payload?: string, headers?: Headers): Promise<Response> {
        if (!headers) 
            headers = new Headers();
        const bearer = 'Bearer ' + token;
        headers.append('Authorization', bearer);
        headers.append("Ocp-Apim-Subscription-Key", process.env.REACT_APP_CLIENTEX_APIKEYVALUE || '');
        if (payload) {
            headers.append('Content-Type', 'application/json');
        }
        headers.append('Accept', 'application/json;application/problem+json;text/html');
        const options = {
            method: method,
            headers: headers,
            credentials: 'include' as RequestCredentials,
            ...(payload && { body: payload }),
        };

        let hasToasted = false;
        try {
            const response = await fetch(endpoint, options);
            if (!response.ok) {
                hasToasted = true;
                response.json().then((data) => {

                    toast.error(data.title || data?.errors?.at(0), {
                        position: 'top-right',
                        autoClose: 3000,
                        transition: Slide,
                        draggable: false,
                        closeOnClick: false,
                        theme: 'colored',
                    });
                });
            }
            return response;
        } catch (error) {
            if (!hasToasted) {
                if (error instanceof Error) {
                    toast.error(error.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        transition: Slide,
                        draggable: false,
                        closeOnClick: false,
                        theme: 'colored',
                    });
                }
            }
            throw error;
        }
    }

    async callApi(
        msalApp: IPublicClientApplication,
        scopes: string[],
        endpoint: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
        payload?: string,
        headers?: Headers,
        authority?: string
    ): Promise<Response> {
        const account = msalApp.getAllAccounts()[0];
        const accessTokenRequest: SilentRequest = {
            scopes: apiConfig.scopes, //THIS IS A HARD CODED VALUE -- FOR LATER, PROBABLY REFACTOR TO PASS CORRECT SCOPES
            account: account,
            authority: authority || b2cPolicies.authorities.signUpSignIn.authority
        };

        try {
            const silentAuthenticationResponse = await msalApp.acquireTokenSilent(accessTokenRequest);
            return this.callApiWithToken(silentAuthenticationResponse.accessToken, endpoint, method, payload, headers);
        } catch (silentAuthenticationError) {
            msalApp.logout();
            throw silentAuthenticationError;
        }
    }
}

export default ApiHelper;
