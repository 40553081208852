import { Outlet, useNavigate } from "react-router-dom";

import { UserProfileContext } from "../Hooks/useProfile";
import { useContext } from "react";

const AdminPage = () => {
    const userProfile = useContext(UserProfileContext);
    const navigator = useNavigate();
    if (!userProfile?.PlatformAdmin)
        navigator("/main")
    return (
        <>
            <Outlet />
        </>
    )
}

export default AdminPage;