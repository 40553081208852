import '../backend.css';
import './Login.css';

import { useNavigate } from 'react-router-dom';
import React, { FunctionComponent, useContext, useEffect } from 'react';

import EccoviaLogo from '../Shared/images/eccovia-logo-transparent.png';
import { SignInButton } from './Components/SignInButton';
import { UserProfileContext } from '../Hooks/useProfile';
import { useMsal } from '@azure/msal-react';

const Login: FunctionComponent = () => {
   const navigator = useNavigate();
   const { instance } = useMsal();
   const userProfile = useContext(UserProfileContext);   

   useEffect(() => {
      if (userProfile?.IsLoggedIn && userProfile?.CanAccessApp) {
         navigator('/main/user/profile');
      }
  }, [userProfile, instance, navigator]);


return (
    <div className="wrapper">
      <section className="login-content">
         <div className="container">
            <div className="row align-items-center justify-content-center height-self-center">
               <div className="col-lg-8">
                  <div className="card auth-card bg-light">
                     <div className="card-body p-0">
                        <div className="row align-items-center auth-content">
                           <div className="col-lg-7 align-self-center">
                              <div className="p-3">
                                 <h2 className="mb-2 text-center text-primary">ClientExchange</h2>
                                 {
                                    !userProfile.CanAccessApp &&
                                    <p className="text-center text-danger">You are not authorized access to this application.</p>
                                 }
                                 <div className="col">
                                    <SignInButton />
                                 </div>
                              </div>
                           </div>
                           <div className="col-lg-5 content-right">
                              <img src={EccoviaLogo} className="img-fluid image-right" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      </div>
    );
};

export default Login;
