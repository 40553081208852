import '../../backend.css';

import { ProviderOption, UserProfile } from '../Models';
import React, { FunctionComponent } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { SignOutButton } from '../../Authentication/Components/SignOutButton';
import SkippyLogo from '../../Shared/images/Skippy.jpg';

interface TopNavProps {
    User: UserProfile;
}

const TopNav: FunctionComponent<TopNavProps> = ({ User }) => {
    const navigate = useNavigate();
    const hamburgerHandler = () => {
        document.body.classList.add("sidebar-main");
    }
    const optionSelect = ((e: any) => {
        navigate("/main/user/profile");
        User.SetActiveProvider(User.ProviderOptions.find(o => o.ProviderName === e.target.innerHTML))
    })

return (
    <div className="iq-top-navbar">
          <div className="iq-navbar-custom">
              <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <div className="d-flex align-items-center w-100">
                      <ul className="navbar-nav ml-auto navbar-list align-items-center justify-content-center">
                        <li className="nav-item">
                        <div id="mobile-hamburger" className="iq-navbar-logo d-flex align-items-center justify-content-between" onClick={hamburgerHandler}>
                      <i className="ri-menu-line wrapper-menu"></i>
                      <a href="../backend/index.html" className="header-logo">
                      </a>
                  </div>
                        </li>
                          <li className="nav-item nav-icon dropdown">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="dropdown-toggle" id="dropdownProviderSelect" data-toggle="dropdown" aria-expanded="false">
                                    { User.ActiveProvider.ProviderName }
                                    <i className="ml-2 mr-2 la la-angle-down card-icon-small"></i>
                                </div>
                                <div className="iq-sub-dropdown dropdown-menu">
                                    {
                                        User?.ProviderOptions?.map((opt: ProviderOption) => (
                                            <div className="dropdown-item" key={opt.ProviderId} onClick={optionSelect}>{opt.ProviderName}</div>
                                        ))
                                    }
                                </div>
                            </div>
                          </li>
                          <li className="nav-item nav-icon dropdown caption-content">
                              <a href="#" className="search-toggle dropdown-toggle" id="dropdownMenuButton4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <img src={SkippyLogo} className="img-fluid rounded" alt="user" />
                              </a>
                              <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <div className="card shadow-none m-0">
                                      <div className="card-body p-0 text-center">
                                          <div className="media-body profile-detail text-center">
                                              <img src="/assets/images/page-img/profile-bg.png" alt="profile-bg" className="profile-bg img-fluid mb-2" />
                                          </div>
                                          <div className="p-3">
                                              <h5 className="mb-1">{User?.AppUser?.entity?.name}</h5>
                                              <Link to={`/main/provider/${User?.AppUser?.entity?.currentProvider}/settings`}>Provider Settings</Link>
                                              <div className="d-flex align-items-center justify-content-center mt-3">
                                                  <Link to="/main/user/profile" className="btn border mr-2">Profile</Link>
                                                  <SignOutButton />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                      </ul>
                  </div>
              </nav>
          </div>
      </div>
    );
};

export default TopNav;
