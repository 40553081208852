import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ApiHelper from "../Shared/ApiHelper";
import { UserProfileContext } from "../Hooks/useProfile";
import { useMsal } from "@azure/msal-react";

const MemberInvitePage = () => {
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());
    const [searchParams, setSearchParams] = useSearchParams();
    const navigator = useNavigate();
    const userProfile = useContext(UserProfileContext);

    useEffect(() => {
        const providerId = searchParams.get("providerId");
        const accept = searchParams.get("accept");
        if (providerId && accept) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/entity/${userProfile?.AppUser?.entity.id}/associateprovider/${providerId}`,
                "POST",
                JSON.stringify({ accept: accept === "true" ? true : false }),
                undefined
            ).then(async response => {
                navigator("/");
            }).catch(err => err);
        }

    }, [searchParams, api, instance, navigator])
}

export default MemberInvitePage;