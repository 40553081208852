import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserProfileContext } from "../Hooks/useProfile";

const ProviderAdminPage = () => {
    const userProfile = useContext(UserProfileContext);

    return userProfile.ProviderAdmin ?
        <Outlet /> :
        <Navigate to="/main/user/profile" />
}

export default ProviderAdminPage;