import { FunctionComponent, useEffect, useState } from "react";
import { ProviderRegistration, RegistrationCompletionStatus } from "../../Shared/Models";

import { Link } from "react-router-dom"

interface ProviderRequestListItemProps {
    Request: ProviderRegistration
}

const ProviderRequestListItem: FunctionComponent<ProviderRequestListItemProps> = ({ Request }) => {
    const [badgeClass, setBadgeClass] = useState<string>('');  
    
    useEffect(() => {
        switch(Request.completionStatus) {
            case RegistrationCompletionStatus.pending:
                setBadgeClass('badge-warning');
                break;
            case RegistrationCompletionStatus.completed:
                setBadgeClass('badge-success');
                break;
            case RegistrationCompletionStatus.declined:
                setBadgeClass('badge-dark');
                break;
            case RegistrationCompletionStatus.failed:
                setBadgeClass('badge-danger');
                break;
        }
    }, [Request])

    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex flex-column">
                    <strong>{Request?.providerName}</strong>
                    <div className="row">
                        <div className="col-4">
                            <span className={`badge badge-pill ${badgeClass}`}>{RegistrationCompletionStatus[Request.completionStatus]}</span>
                        </div>
                        <div className="col-4">
                            {new Date(Request.requestedDate).toLocaleDateString('en-US')}
                        </div>
                        <div className="col-4 text-right">
                            <Link to={`/main/admin/providerrequest/${Request.id}`}><strong>DETAILS</strong></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProviderRequestListItem;