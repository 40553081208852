import '../../backend.css';

import React, { FunctionComponent, useContext  } from 'react';

import EccoviaLogo from '../../Shared/images/cex_email_logo.png';
import { UserProfile } from '../../Shared/Models';
import { UserProfileContext } from '../../Hooks/useProfile';

const RegistrationComplete: FunctionComponent = () => {
    const userProfile: UserProfile = useContext(UserProfileContext);
return (
        <div className="row d-flex justify-content-center min-height-100vh bg-e5 pl-5 pr-5">
            <div id="registrationCompleted" className="d-flex flex-column p-5 gap-l col-md-7 col-md-offset-5 align-items-center">
                <p>
                    You have successfully created your <strong>ClientExchange</strong> login and password for {userProfile?.ActiveProvider?.ProviderName || 'one of our hosted providers'}.
                    You will use this new account to sign in to the messaging features in <strong>ClientTrack</strong> so that you can start chat and broadcast communications with your clients. To access the messaging features, click the button below to log into <strong>ClientTrack</strong>.
                </p>
                <a className="btn btn-primary" href="https://clienttrack.eccovia.com">Open ClientTrack</a>
                <img src={EccoviaLogo} alt='Client Exchange Logo' width="50%"/>
            </div>
        </div> 
    );
};

export default RegistrationComplete;
