import { useMsal } from "@azure/msal-react";
import LogoutRequest from "../Models/LogoutRequest";

export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
          instance.logout(LogoutRequest);
    }

    return (
        <button className="btn border" onClick={handleLogout} >Sign Out</button>
    )
};