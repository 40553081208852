import { useEffect, useState } from "react";

/**
 * Adds a tooltip to a component. This happens AFTER component has rendered.
 * Component must have data-toggle="tooltip" data-placement="{top|bottom|left|right}" data-original-title="{Tooltip content}"
 * See https://templates.iqonic.design/skycall/html/backend/ui-tooltips.html for more options.
 * @param dependencies A list of components to watch.
 * If present, effect will only activate if the values in the list change.
 */
const useTooltip = (dependencies?: any[]) => {
    dependencies = dependencies ?? [];
    const [ tooltipComponents, setTooltipComponents ] = useState<any | undefined>();

    useEffect(() => {
        const w = window as any;
        if(w.jQuery) {
            setTooltipComponents(w.jQuery('[data-toggle="tooltip"]'));
        }
        else
            console.log("Tooltip could not be added because JQuery could not be found.");
    // eslint-disable-next-line
    }, dependencies);

    useEffect(() => {
        if(tooltipComponents) {
            tooltipComponents.tooltip();
            tooltipComponents.on("click", () => {
                tooltipComponents.tooltip("hide");
            });
        }
    }, [tooltipComponents]);
};

export default useTooltip;