import { FunctionComponent, useEffect } from "react";
import { b2cPolicies, loginRequest } from "./AuthConfig";

import { useMsal } from "@azure/msal-react";
import { RedirectRequest } from "@azure/msal-browser";

const TeamMemberRegistrationB2CTriggerPage: FunctionComponent = () => {
    const { instance } = useMsal();
    const domain: string = process.env.REACT_APP_DOMAIN || '';
    const startPageRoute: string = process.env.REACT_APP_TEAMMEMBER_REGISTRATION_STARTPAGE || '';
    
    useEffect(() => {
        const registrationRequest: RedirectRequest = {
            redirectStartPage: domain + startPageRoute,
            redirectUri: domain,
            authority: b2cPolicies.authorities.signUp.authority,
            scopes: loginRequest.scopes
        }
        
        instance.loginRedirect(registrationRequest)
        .catch(err => { })
    })

    return (
        <>
            Please register
        </>
    )
}

export default TeamMemberRegistrationB2CTriggerPage;