import '../backend.css';

import React, { FunctionComponent, useState } from 'react';

import EccoviaLogo from '../Shared/images/eccovia-logo-transparent.png';

const UserInvitePage: FunctionComponent = () => {

return (
    <>
    <div className="wrapper">
      <section className="login-content">
         <div className="container">
            <div className="row align-items-center justify-content-center height-self-center">
               <div className="col-lg-8">
                  <div className="card auth-card bg-light">
                     <div className="card-body p-0">
                        <div className="row align-items-center auth-content">
                           <div className="col-lg-7 align-self-center">
                              <div className="p-3">
                                 <h2 className="mb-2 text-center text-primary">User Invite</h2>
                                 <p className="text-center text-grey">Connect with a user by sending an invite to their email</p>
                                 <div className="row justify-content-around align-items-center mt-3">
                                    <form>
                                        <div className="form-row">
                                            <div className="col">
                                                <label>User email</label>
                                                <input type="text" className="form-control" id="exampleInputDisabled5" value="email@mail.com" />
                                            </div>
                                        </div>
                                        <div className="form-row mt-2">
                                        <div className="col">
                                                <label>Organization</label>
                                                <select className="form-control" >
                                                    <option>Select an organization to use for the invitation</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-4">
                                            <div className="col">
                                                <button className="btn btn-primary w-100">Send Invite</button>
                                            </div>
                                        </div>
                                    </form>
                                 </div>
                              </div>
                           </div>
                           <div className="col-lg-5 content-right">
                              <img src={EccoviaLogo} className="img-fluid image-right" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      </div>
    </>
    );
};

export default UserInvitePage;
