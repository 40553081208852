import { Access, EntityRoles } from "../Shared/Models";
import { FunctionComponent, useEffect, useState, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import ApiHelper from "../Shared/ApiHelper";
import { ToastConfig } from "../Shared/ToastConfig";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { UserProfileContext } from "../Hooks/useProfile";

type Inputs = {
    roles: string[]
}

const UserDetailsPage: FunctionComponent = () => {
    const location = useLocation();
    const [ user, setUser] = useState<EntityRoles | undefined>();
    const [ providerRoles, setProviderRoles] = useState<Access[]>([])
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const { handleSubmit, setValue, getValues } = useForm<Inputs>({ mode: "onChange" });
    const userProfile = useContext(UserProfileContext);

    useEffect(() => {
        setUser(location?.state?.EntityRole);
    }, [location, user?.roles])

    useEffect(() => {
        if(user)
            api.callApi(
                instance, 
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/provider/${userProfile.ActiveProvider.ProviderId}/roles`,
                "GET")
            .then(apiCallbackHandler);
    }, [instance, api, user]);

    const apiCallbackHandler = async (res: Response) => {
        var data: Access[] = await res.json();
        setProviderRoles(data);
        setValue("roles", data.filter((a: Access) => user?.roles?.find((ur: Access) => a.id === ur.id)).map((r: Access) => r.id));
     }

     const handleOnSubmit: SubmitHandler<Inputs> = (formValues: Inputs) => {
        if (user) {
            api.callApi(
                instance, 
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/provider/${userProfile.ActiveProvider.ProviderId}/users/${user?.entity.id}/roles`,
                "PUT",
                JSON.stringify({ UserRoles: formValues.roles.filter(e => e != "") }))
             .then(apiSaveRolesCallbackHandler);
        }
     }

     const apiSaveRolesCallbackHandler = async (res: Response) => {
        toast.success("Roles have been successfully updated.", ToastConfig);
     }

    return (
        <>
            <div className="card card-block card-stretch card-height">
                <div className="card-header">
                    <div className="header-title">
                        <h4 className="card-title">{user?.entity.name}</h4>
                    </div>
                </div>
                <div className="card-body">
                    <ul className="list-inline p-0 m-0 iq-contact-rest">
                        <li className="mb-3 d-flex align-items-center">
                            <span>
                                <i className="mr-3">
                                    <svg width="20" className="svg-icon" id="up-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"></path>
                                    </svg>
                                </i>
                            </span>
                            <p className="mb-0 font-size-16 line-height">{user?.entity?.identifiers.find((x: any) => x.type === 1)?.value}</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="card card-block card-stretch card-height">
                <div className="card-header">
                    <div className="header-title">
                        <h4 className="card-title">Roles</h4>
                    </div>
                </div>
                <div className="card-body p-2">
                    <form onSubmit={handleSubmit(handleOnSubmit)} method="POST">
                        <ul className="d-flex flex-wrap justify-content-between p-0">
                            {providerRoles?.map((role: Access, index: number) => (
                                <li key={index} className="m-1">
                                    <label>{role.name}</label>
                                    <input 
                                        className="checkbox-input m-1" 
                                        type="checkbox"
                                        value={role.id}
                                        onChange={e => {
                                            if(e.target.checked) {
                                                setValue("roles", [...getValues("roles"), role.id])
                                            } else {
                                                setValue("roles", getValues("roles").filter((r: string) => r !== role.id))
                                            }
                                          }}
                                        defaultChecked={getValues("roles").includes(role.id)}
                                        />
                                </li>
                            ))}
                        </ul>
                        <div>
                            <button className="btn btn-primary w-100" type="submit">Save Roles</button>
                        </div>
                    </form>
                </div>
            </div> 
        </>
    )
}

export default UserDetailsPage;