import '../../backend.css';

import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import EccoviaLogo from '../images/eccovia-logo-transparent.png';
import { Link, NavLink } from 'react-router-dom';
import { UserProfileContext } from '../../Hooks/useProfile';

const LeftNav: FunctionComponent = () => {
    const userProfile = useContext(UserProfileContext);
    const [isProvidermAdmin, setIsProviderAdmin] = useState(false);

    const closeSidebar = () => {
        document.body.classList.remove("sidebar-main");
    }

    useEffect(() => {
        setIsProviderAdmin(userProfile.ProviderAdmin);
    }, [userProfile])
    
return (
    <div className="small-saidbar">
          <div className="iq-sidebar-logo d-flex justify-content-between">
              <Link to="/main/user/profile">
                <img src={EccoviaLogo} className="img-fluid" alt="logo" />
              </Link>
              <div className="iq-menu-bt-sidebar">
                  <div className="iq-menu-bt align-self-center" onClick={closeSidebar}>
                      <div className="wrapper-menu open">
                          <div className="main-circle"><i className="ri-close-line"></i></div>
                      </div>
                  </div>
              </div>
          </div>
          <nav className="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" className="iq-menu">
                  <li className="">
                    <NavLink to="/main/user/profile" className={({ isActive }) => isActive ? "active" : ""} title="Home">
                        <i className="las la-home iq-arrow-left"></i><span className="menu-text">Home</span>
                    </NavLink>
                </li>
                {/* <li className="">
                    <NavLink to="/feedback" className={({ isActive }) => isActive ? "active" : ""} title="Feedback">
                        <i className="la la-book iq-arrow-left"></i><span className="menu-text">Feedback</span>
                    </NavLink>
                </li> */}
                  
                { userProfile?.PlatformAdmin && (
                    <li className="">
                        <a href="#admin" className="collapsed" data-toggle="collapse" aria-expanded="false">                
                            <i className="las la-key iq-arrow-left"></i><span>Admin</span>
                        </a>
                        <ul id="admin" className="iq-submenu collapse" data-parent="#admin">
                            <li className="">
                                <NavLink to="/main/admin/providerrequests" className={({ isActive }) => isActive ? "active" : ""}>
                                    <i className="las la-sign-in-alt"></i><span>Provider Requests</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    )
                }
                <hr />
                { isProvidermAdmin && (
                <li className="">
                  <a href="#providermenu" className="collapsed" data-toggle="collapse" aria-expanded="false">                
                    <i className="las la-key iq-arrow-left"></i><span>Provider</span>
                  </a>
                  <ul id="providermenu" className="iq-submenu collapse" data-parent="#admin">
                    <li className="">
                      <NavLink to={`/main/provider/${userProfile?.ActiveProvider.ProviderId}/usersearch`} className={({ isActive }) => isActive ? "active" : ""}>
                        <i className="las la-sign-in-alt"></i><span>User Management</span>
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink to={`/main/provider/${userProfile?.ActiveProvider.ProviderId}/rolemanagement`} className={({ isActive }) => isActive ? "active" : ""}>
                        <i className="las la-sign-in-alt"></i><span>Role Management</span>
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink to={`/main/provider/${userProfile?.ActiveProvider.ProviderId}/settings`} className={({ isActive }) => isActive ? "active" : ""}>
                        <i className="las la-sign-in-alt"></i><span>Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                ) }
            </ul>
          </nav>
    </div>
    );
};

export default LeftNav;
