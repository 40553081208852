import { Outlet, useNavigate } from "react-router";

import { UserProfileContext } from "../../Hooks/useProfile"
import { useContext, useEffect } from "react"
import { useMsal } from "@azure/msal-react";

const AuthRoute = () => {
    const userProfile = useContext(UserProfileContext);
    const { accounts } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        if (accounts.length === 0 && !userProfile?.IsLoggedIn)
            navigate("/login");
    }, [accounts.length, navigate, userProfile, userProfile?.IsLoggedIn]);

    return accounts.length > 0 && !!userProfile.AppUser ? 
        <Outlet /> : ( 
        <div className="text-center mt-5">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        )
}

export default AuthRoute;