import { b2cPolicies, loginRequest } from "../AuthConfig";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";

export const SignInButton = () => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleLogin = () => {
        setAnchorEl(null);
        const signInRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: loginRequest.scopes,
            redirectUri: loginRequest.redirectUri
        }
        instance.loginRedirect(signInRequest)
            .then(data =>{
                console.log( data )
            } )
            .catch(err => console.log( err ));
    }

    return (
        <button className="btn btn-primary w-100" onClick={handleLogin} >Sign In</button>
    )
};

export default SignInButton;