import { FunctionComponent, useContext, useEffect, useState } from "react";

import ApiHelper from "../Shared/ApiHelper";
import { loginRequest } from "./AuthConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../Hooks/useProfile";

const LinkAccountPage: FunctionComponent = () => {
    const { instance, accounts } = useMsal();
    const [api] = useState(new ApiHelper());
    const userProfile = useContext(UserProfileContext);
    const [isLinked, setIsLinked] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(!userProfile.IsLoading && accounts.length > 0) {
            api.callApi(
                instance,
                loginRequest.scopes,
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/entity/linkaccount`,
                "POST",
                JSON.stringify({})
            ).then(res => {
                userProfile.ForceRefresh()
                setIsLinked(true);
            }).catch(err => console.error(err));
        }
    }, [api, instance, navigate, accounts, userProfile, userProfile.IsLoading]);

    useEffect(()  => {
        if(!userProfile.IsLoading && isLinked) {
            navigate("/registrationcompleted");
        }
    }, [userProfile.IsLoading, isLinked, navigate]);

    return (
        <>
            Linking accounts please wait... 
        </>
    )
}

export default LinkAccountPage;