import '../index.css';

import { useEffect, useState, useContext } from "react";

import { Access } from "../Shared/Models";
import ApiHelper from '../Shared/ApiHelper';
import { Link } from 'react-router-dom';
import RoleListItem from "./Components/RoleListItem";
import { ToastConfig } from '../Shared/ToastConfig';
import { toast } from 'react-toastify';
import { useMsal } from "@azure/msal-react";
import { UserProfileContext } from "../Hooks/useProfile";

const RoleManagementPage = () => {
    const [api] = useState(new ApiHelper());
    const [roles, setRoles] = useState<Access[]>([]);
    const { instance } = useMsal();
    const userProfile = useContext(UserProfileContext);
    
    useEffect(() => {
        api.callApi(
            instance, 
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/provider/${userProfile.ActiveProvider.ProviderId}/roles`,
            "GET")
         .then(apiCallbackHandler);
    }, [api, instance, userProfile.ActiveProvider.ProviderId])

    const apiCallbackHandler = async (res: Response) => {
        var data = await res.json();
        if (data)
            setRoles(data);
     }

     const deleteRoleCallback = (role: Access) => {
        toast.success(`${role.name} has been deleted.`, ToastConfig);
        setRoles(oldRoles => oldRoles.filter(x => x.id !== role.id));
     }

    return (
        <>
            <div className="d-flex align-items-center">
                <h4>Role Management</h4>
                <Link className="btn btn-outline ml-2 d-flex align-items-center" to="create">
                    <i className="la la-plus-circle create"></i>
                </Link>
            </div>
            <div className="table-responsive">
                <table id="user-list-table" className="table table-striped dataTable mt-4" role="grid" aria-describedby="user-list-page-info">
                    <thead>
                        <tr className="ligth">
                            <th>Role Name</th>
                            <th>Scopes</th>
                            <th style={{ minWidth: '100px' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { roles.map((role: Access) => <RoleListItem key={role.id} providerId={userProfile.ActiveProvider.ProviderId} item={role} onDeleteCallback={(e: any) => deleteRoleCallback(role) } />)}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default RoleManagementPage;