import { FunctionComponent, useState } from "react";

import { Access } from "../../Shared/Models";
import ApiHelper from "../../Shared/ApiHelper";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import useTooltip from "../../Hooks/useTooltip";

interface RoleListItemProps {
    item: Access,
    onDeleteCallback: Function,
    providerId: string
}

const RoleListItem: FunctionComponent<RoleListItemProps> = (props: RoleListItemProps) => {
    const { item, onDeleteCallback, providerId } = props;
    const [api] = useState<ApiHelper>(new ApiHelper());
    const { instance } = useMsal();

    useTooltip();

    const deleteRole = async () => {
        api.callApi(
            instance, 
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/provider/${providerId}/roles/${item.id}`,
            "DELETE")
         .then(response => onDeleteCallback(item))
    }

    return (
        <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.scope.reduce((acc: string, item: any) => {return `${acc} ${item.scope}`}, '')}</td>
            <td>                                           
                <div className="d-flex align-items-center list-user-action">
                    <Link to={`details/${item.id}`} className="btn btn-sm bg-primary mr-2" data-toggle="tooltip" data-placement="top" data-original-title="Edit" state={{ role: item}}><i className="ri-pencil-line mr-0"></i></Link>
                    <button onClick={deleteRole} className="btn btn-sm bg-primary" data-toggle="tooltip" data-placement="top" data-original-title="Delete"><i className="ri-delete-bin-line mr-0"></i></button>
                </div>
            </td>
        </tr>
    )
}

export default RoleListItem;