import "../index.css";

import { Access, Permission, ScopeView, ScopeRequest, Scope } from "../Shared/Models";
import { FunctionComponent, useEffect, useState, useContext } from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import ApiHelper from '../Shared/ApiHelper';
import { ToastConfig } from "../Shared/ToastConfig";
import { toast } from "react-toastify";
import { useMsal } from '@azure/msal-react'
import { UserProfileContext } from "../Hooks/useProfile";

type Inputs = {
    name: string,
    scopes: string[]
}

const RoleDetailsPage: FunctionComponent = () => {
    const { register, handleSubmit, formState: { errors }, getValues } = useForm<Inputs>({ mode: "onChange" });
    const location = useLocation();
    const { accounts, instance } = useMsal();
    const navigator = useNavigate();
    const [api] = useState(new ApiHelper());
    const [scopes, setScopes] = useState([]);
    const [isEditRole] = useState<boolean>(location?.state && "role" in location?.state);
    const [editableRole, setEditableRole] = useState<Access | undefined>();
    const userProfile = useContext(UserProfileContext);
    const { onChange, onBlur, name, ref } = register('name'); 

    useEffect(() => {
        if (isEditRole) {
            setEditableRole({ ...location.state.role });
        }
    }, [isEditRole, location]);

    const atLeastOne = () =>
        getValues("scopes").length ? true : "You must select at least 1 scope.";

    const handleOnSubmit: SubmitHandler<Inputs> = (formValues: Inputs) => {
        const valuesWithScopes: ScopeRequest[] = formValues.scopes.map((scope: string): ScopeRequest => { return { scope: scope, permission: Permission.Full, deny: Permission.None } });
        const postObject = { name: formValues.name === "" ? editableRole?.name : formValues.name, scopes: valuesWithScopes };
        if(isEditRole && !!editableRole) {
            api.callApi(
                instance, 
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/provider/${userProfile.ActiveProvider.ProviderId}/roles/${editableRole?.id}`,
                "PUT",
                JSON.stringify(postObject))
            .then(createEditRoleCallbackHandler)
            .catch(createEditRoleErrorHandler);
        } else if(!isEditRole) {
            api.callApi(
                instance, 
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/provider/${userProfile.ActiveProvider.ProviderId}/roles`,
                "POST",
                JSON.stringify(postObject))
            .then(createEditRoleCallbackHandler)
            .catch(createEditRoleErrorHandler);
        } else {
            toast.error("Editable role is not configured correctly.", ToastConfig);
        }
    }

    const createEditRoleCallbackHandler = async (res: Response) => {
        if (res.ok) {
            toast.success(`Role ${isEditRole ? "edited" : "created"} successfull`, ToastConfig);
            navigator(`/main/provider/${userProfile.ActiveProvider.ProviderId}/rolemanagement`);
        }
    }

    const createEditRoleErrorHandler = (err: any) => {
        toast.error(err.message, ToastConfig);
    }

    useEffect(() => {
        api.callApi(
            instance, 
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/scope`,
            "GET")
         .then(apiCallbackHandler);
    }, [accounts, instance, api]);

    const apiCallbackHandler = async(res: Response) => {
        const data = await res.json();
        setScopes(data);
    }

    return (
        <>
            <button className="btn btn-link pl-0" onClick={() => navigator(-1)}>
                <i className="la la-arrow-circle-left mr-2"></i>
                <span className="menu-text">Role Management</span>
            </button>

            <div className="card auth-card bg-light">
                <div className="card-body p-0">
                <div className="row align-items-center auth-content">
                    <div className="col-lg-7 align-self-center">
                        <h3 className="mb-2 mt-1 text-center text-primary">{ isEditRole ? "Edit Role" : "New Role"}</h3>
                    </div>
                </div>
                <div className="justify-content-around align-items-center w-100">
                    <form onSubmit={handleSubmit(handleOnSubmit)} method="POST">
                        
                        <input type="hidden" value={userProfile.ActiveProvider?.ProviderId} name="customerId" />
                        <div className="form-row mb-2 p-2">
                            <div className="col">
                                <div className="d-flex">
                                    <label>Role Name</label>
                                    {
                                        errors.name && <span className="ml-2 text-danger">Required.</span>
                                    }
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={editableRole?.name ?? ""}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    name={name}
                                    ref={ref}
                                />
                            </div>
                        </div>

                            <div className="form-row mb-2 p-2">
                                <div className="col">
                                    <div className="d-flex">
                                        <label>Scopes</label>
                                        {
                                            errors.scopes && <span className="ml-2 text-danger">Select at least 1 scope.</span>
                                        }
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Permission</th>
                                                    <th scope="col">Deny</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scopes?.map((scope: ScopeView, index: number) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                value={scope.id}
                                                                defaultChecked={editableRole?.scope.find((s: Scope) => s.scope === scope.id) !== undefined}
                                                                {...register("scopes", { validate: atLeastOne }) }
                                                            />
                                                        </td>
                                                        <td className="w-25">{scope.name}</td>
                                                        <td>{scope.description}</td>
                                                        <td style={{width: "125px"}}>
                                                            <select disabled className="form-control" defaultValue={Permission.Full}>
                                                                <option value={Permission.Full}>Full</option>
                                                            </select>
                                                        </td>
                                                        <td style={{width: "125px"}}>
                                                            <select disabled className="form-control" defaultValue={Permission.None}>
                                                                <option value={Permission.None}>None</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2">
                                <button className="btn btn-primary w-100" type="submit">{ isEditRole ? "Edit" : "Create"} Role</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoleDetailsPage;