import '../../backend.css';

import React, { FunctionComponent, useContext } from 'react';

import LeftNav from './LeftNav';
import { Outlet } from 'react-router-dom';
import TopNav from './TopNav';
import { UserProfile } from '../Models';
import { UserProfileContext } from '../../Hooks/useProfile';

const Main: FunctionComponent = () => {
    const user = useContext<UserProfile>(UserProfileContext);

    return  (
        <>
            <TopNav User={user}/>
            <LeftNav />
            <div className="content-page">
                <div className="container-fluid">
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default Main;