import '../backend.css';

import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import EccoviaLogo from '../Shared/images/eccovia-logo-transparent.png';
import { UserProfile } from '../Shared/Models';
import { UserProfileContext } from '../Hooks/useProfile';

const UserProfilePage: FunctionComponent = () => {
    const [emailOptIn, setEmailOptIn] = useState(false);
    const userProfile: UserProfile = useContext(UserProfileContext);
return (
    <>
        <div className="row">
            <div className="col-md-6 col-lg-4">
                <div className="card car-transparent" style={{ background: "#fcfcfc"}}>
                    <div className="card-body p-0" >
                        <div className="profile-image position-relative d-flex justify-content-center">
                            <img src={EccoviaLogo} className="img-fluid rounded" alt="profile" />
                        </div>
                        <div className="profile-overly">
                            <h3>{userProfile?.AppUser?.entity?.name}</h3>
                            <span>User</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-8">
                <div className="card card-block card-stretch card-height">
                    <div className="card-header">
                        <div className="header-title">
                            <h4 className="card-title">Contact Information</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <ul className="list-inline p-0 m-0 iq-contact-rest">
                            <li className="mb-3 d-flex align-items-center">
                            <span><i className="mr-3">
                                <svg width="20" className="svg-icon" id="up-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"></path>
                                </svg>
                            </i></span>
                            <p className="mb-0 font-size-16 line-height">{userProfile?.B2CUser?.username}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <hr /> 
    </>
    );
};

export default UserProfilePage;
